import React from "react";
import { Link } from "gatsby";
import { Layout } from "StaticPages/Layouts";
import Helmet from "react-helmet";

export default () => {
    return <>
        <Helmet title="VISUALIZについて | VISUALIZ INC" />
        <Layout>
            <main className="Philosophy">
                <div className="BreadClumb TopBar" id="head">
                    <p><Link to="/">VISUALIZ inc</Link>＞<Link to="/service">サービス</Link>＞<span>BIDnavi DATA サンプル</span></p>
                </div>
                <h2 className="main-subttl MSize">BIDnavi DATA サンプル<br />Smaple</h2>
                <p className="main_about MSize Btm190">
                    BIDnavi DATAを実際に埋め込んだ際のサンプルをご確認いただけます。</p>

                <article>
                    <div>
                        Coming soon
                        現在準備中です。
                    </div>
                </article>

            </main>
        </Layout>
    </>;
};